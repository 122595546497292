<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="TMW Transactions"/>
        <v-spacer></v-spacer>
          <!-- Export Dialog with Button -->
          <v-dialog v-model="dialog_exportbi" max-width="700px">
            <template v-slot:activator="{ on }">
                <v-btn 
                  v-show="canDo && canDo.can.includes('export_powerbi')"
                  class="mx-2" depressed dark color="red darken-4" v-on="on">
                  <v-icon left dark>mdi-upload</v-icon> Export BI
                </v-btn>
            </template>
            <!-- Modal Content -->
            <v-card>
              <v-card-title>
                <span class="headline">Export Data to PowerBI</span>
              </v-card-title>
              <v-card-text>
                <v-form v-model="form_valid" ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-menu
                          v-model="export_datePicker"
                          :close-on-content-click="true"
                          max-width="290"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              :value="exportBI_date_formatted"
                              label="Transaction Date"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="exportBI.selected_date"
                            min="2020-05-20"
                            :max="moment().add(-1, 'days').format('YYYY-MM-DD')"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog_exportbi = false">ยกเลิก</v-btn>
                <v-btn color="blue darken-1" text @click="dialog_exportbi = false; exportPowerBI(exportBI.selected_date);">ตกลง</v-btn>
              </v-card-actions>
            </v-card>
            <!-- End Modal Content -->
          </v-dialog>

          <!-- Export Dialog with Button -->
          <v-dialog v-model="export_dialog" max-width="700px">
            <template v-slot:activator="{ on }">
                <v-btn 
                  v-show="canDo && canDo.can.includes('export_tmw_transactions')"
                  class="mx-2" depressed dark color="primary" v-on="on">
                  <v-icon left dark>mdi-upload</v-icon> Export
                </v-btn>
            </template>
            <!-- Modal Content -->
            <v-card>
              <v-card-title>
                <span class="headline">Export TMW Transaction Data</span>
              </v-card-title>

              <v-card-text>
                  <p>คุณต้องการ Export ข้อมูลตามที่ได้ทำการ filter ไว้ใช่หรือไม่</p>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="export_dialog = false">ยกเลิก</v-btn>
                <v-btn color="blue darken-1" text @click="export_dialog = false; exportTMWReport(options);">ตกลง</v-btn>
              </v-card-actions>
            </v-card>
            <!-- End Modal Content -->
          </v-dialog>

          <!-- Import Dialog with button -->
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on }">
                <v-btn 
                  v-show="canDo && canDo.can.includes('import_tmw_transactions')"
                  class="mx-2" depressed dark color="primary" v-on="on">
                  <v-icon left dark>mdi-download</v-icon> Import
                </v-btn>
            </template>
            <!-- Modal Content -->
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>
              <v-card-text>
                <v-form v-model="form_valid" ref="form">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-file-input
                            v-model="editedItem.import_file"
                            :rules="[validate_rules.required,validate_rules.filesize,validate_rules.filetype]"
                            accept="text/csv"
                            placeholder="Browse a csv file"
                            label="Topup List"
                        ></v-file-input>
                    </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save">Save</v-btn>
              </v-card-actions>
            </v-card>
            <!-- End Modal Content -->
          </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-4" flat>
          <!-- Action Button -->
          <!-- <v-spacer></v-spacer> -->
          <v-col class="d-flex pl-0" cols="4" sm="4">
            <v-text-field
              label="POItrans, Transaction ID ,SSN, Phone ..."
              v-model="options.filter.keyword"
              hide-details
              prepend-inner-icon="mdi-magnify"
              class="hidden-sm-and-down"
              clearable
              @keyup.native.enter="getDataFromApi"
            /> 
          </v-col>
          <v-col class="d-flex px-0" cols="2" sm="2">
            <v-select
              :items="transactionTypes"
              label="Transaction Types"
              v-model="options.filter.transaction_type"
              no-data-text="Select.."
              item-text="text"
              item-value="value"
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
              :items="wallet_codes"
              label="Wallet Type"
              v-model="options.filter.wallet_code"
              no-data-text="Select.."
              item-text="text"
              item-value="value"
              clearable
              hide-details
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="3" sm="3">
            <v-menu
              v-model="filterDatePicker"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="filter_date_formatted"
                  label="TMW transaction at"
                  readonly
                  v-on="on"
                  @click:clear="clearFilterDaterange()"
                  hide-details
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.filter.date_range"
                @change="filterDatePicker = false"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
          <v-btn class="mx-2" depressed dark small color="primary" @click="getTMWReport(options);">
            Search
          </v-btn>
        </v-toolbar>
        <v-data-table 
            :headers="headers"
            :items="dataLisings" 
            :options.sync="options"
            :server-items-length="totalResult"
            :loading="loadingDataTable"
            :footer-props="{
              'items-per-page-options': [25, 100, 500]
            }"
            class="text-small"
            multi-sort
            no-data-text="There is no data"
        >
          
          <template #item.dm_amount="{value}">
              {{ value | comma}}
          </template>
          <template #item.tmw_amount="{value}">
              {{ value | comma}}
          </template>
          <template #item.dm_transfer_date="{value}">
              <span v-show="value">{{ value | datetime}}</span>
          </template>
          <template #item.tmw_transaction_date="{value}">
              {{ value | datetime}}
              <!-- {{ moment(value).format('DD-MM-YYYY HH:mm:ss') }} -->
          </template>
          <!-- <template #item.action="{item}">
                <v-btn class="ma-1" outlined color="primary" icon tile small :disabled="['approve_request','approved'].includes(item.status)">
                  <v-icon small @click="editItem(item)">mdi-upload</v-icon>
                </v-btn>
                <v-btn class="ma-1" outlined color="primary" icon tile small>
                  <v-icon small @click="()=>$router.push(`/incentive/topup/${item.id}/details`)">mdi-text-box</v-icon>
                </v-btn>
                <v-btn class="ma-1" outlined color="red" icon tile small :disabled="['approve_request','approved'].includes(item.status)">
                  <v-icon small @click="deleteItem(item)">mdi-trash-can-outline</v-icon>
                </v-btn>
          </template> -->
        </v-data-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'TMWtransactions',
  components: {
      pageHeading
  },
  data: () => ({
    search: '',
    dialog: false,
    dialog_exportbi:false,
    export_datePicker:false,
    export_dialog: false,
    datePicker: false,
    filterDatePicker: false,
    upload_file: null,
    editedItem: {
      id: -1,
      import_file: null,
      replace: true
    },
    defaultItem: {
      id: -1,
      import_file: null,
      replace: true
    },
    exportBI: {
      selected_date: moment().add(-1, 'days').format('YYYY-MM-DD')
    },
    form_valid : false,
    validate_rules : {
        required: v => !!v || 'This field is required',
        select2: v => {
          if(typeof v === 'object'){
            return (v.value !== '') || 'This field is required';
          } else {
            return true
          }
        },
        // select: v => { return (v !== '') || 'This field is required' },
        filesize:  v => !v || v.size < 2000000 || 'Updload size should be less than 2 MB!',
        // filetype:  v => !v || ["text/csv"].includes(v.type) || 'Please upload .csv file only',
        filetype:  v => {
          if(v){
            var ext = v.name.split('.').pop();
            return ["csv"].includes(ext) || 'Please upload .csv file only';
          } else {
            return 'This field is required'
          }
        }
        // excel type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    options: { 
        page: 1,
        itemsPerPage: 25,
        sortBy: ['tmw_transaction_date'],
        sortDesc: [true],
        multiSort: true,
        mustSort: true,
        filter: {
            keyword: '',
            transaction_type: '',
            wallet_code : '',
            date_range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
        },
    },
    wallet_codes : [
      {value : 'TPC' , text : 'TPC'},
      {value : '1112D' , text : '1112D'}
    ]
  }),
  watch: {
    'options.sortBy': function (newVal, oldVal){
         //to work with changes in someOtherProp
        if(newVal){
          this.getTMWReport(this.options);
        }
     },
    dialog (val) {
      val || this.close()
    },
    filterDatePicker (val){
      if(val && this.options.filter.date_range.length == 0){
        this.options.filter.date_range = [ moment().subtract(0, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
      }
    }
  },
  methods: {
    ...mapActions(['genCan','getTMWReport','getTransactionTypes','importTMW','exportTMWReport','exportPowerBI']),
    getDataFromApi() {
        this.getTMWReport(this.options);
    },
    clearFilterDaterange(){
      this.options.filter.date_range = [];
    },
    addItem(){
      this.dialog = true;
    },
    editItem (item) {
      this.editedIndex = this.tmwReport.data.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.replace = true
      this.dialog = true
    },
    deleteItem (item) {
        confirm('Are you sure you want to delete this topup?') && this.deleteIncentiveTopups(item.id);
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.$refs.form.resetValidation() // prevent validate error show again when click add
      }, 300)
    },
    save () {
      if(this.form_valid){
        this.editedItem.replace = true;
        this.importTMW(this.editedItem);
        this.close()
      }
    },
  },
  computed: {
    ...mapGetters(['canDo','loadingDataTable','tmwReport','transactionTypes']),
    dataLisings() { 
        var res = this.tmwReport ? this.tmwReport.data : [];
        return  res;
    },
    totalResult(){
        return (this.tmwReport ? this.tmwReport.count : null) || 0;
    },
    headers(){
      return [
        // {text: "ID", value: 'tmw_id',width: '90px'},
        {text: "TMW transaction at", value: 'tmw_transaction_date', align: 'center',width: '120px'},
        {text: "Tranfer Date", value: 'dm_transfer_date', align: 'center',width: '120px'},
        {text: "POItrans", value: 'poi_trans', align: 'left',width: '120px',sortable: false},
        {text: "TransactionID", value: 'tmn_transaction_id', align: 'left',width: '150px'},
        {text: "Transaction Type", value: 'transaction_type',align: 'center',width: '150px',sortable:false},
        {text: "Wallet", value: 'wallet_code',align: 'center',width: '150px',sortable:false},
        {text: "RefID", value: 'order_no',align: 'center',width: '150px'},
        {text: "SSN ID", value: 'ssn', align: 'center',width: '150px',sortable:false},
        {text: "Phone No.", value: 'phone', align: 'center',width: '120px',sortable:false},
        {text: "Transaction Amount", value: 'dm_amount', align: 'right',width: '100px'},
        {text: "TMW Amount", value: 'tmw_amount', align: 'right',width: '100px'},
        {text: "Varient", value: 'diff', align: 'right',width: '100px',sortable:false},
        // {text: "TMW Transfer Status", value: 'tmw_transfer_status', align: 'right',width: '100px',sortable:false},
        {text: "Verify Status", value: 'tmw_status', align: 'center',width: '100px',sortable:false},
        {text: "Notify Status", value: 'tmw_status', align: 'center',width: '100px',sortable:false},
        {text: "TMW Status", value: 'tmw_status', align: 'right',width: '100px',sortable:false},
        // {text: "Action", value: 'action', sortable: false, align: 'center', width: '200px'}, 
      ]
    },

    formTitle () {
        return 'Import TMW Transactions'
    },
    incentive_date_formatted () {
        return this.editedItem.incentive_date ? moment(this.editedItem.incentive_date).format('DD-MM-YYYY') : '';
    },
    filter_date_formatted () {
      if(!this.options.filter.date_range) 
        return '';
        
      var date_format = this.options.filter.date_range.map(x => moment(x).format('DD-MM-YYYY'));
        return typeof date_format == 'object' ? date_format.join(' - ') : '';
      
    },
    exportBI_date_formatted () {
        return moment(this.exportBI.selected_date).format('DD-MM-YYYY');
    },
    getEndDate(){
      console.log(moment().add(-1, 'days').format('YYYY-MM-DD'));
      return moment().add(-1, 'days').format('YYYY-MM-DD');
    }
  },
  mounted() {
    this.genCan();
    this.getTransactionTypes();
    this.getTMWReport(this.options);
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}
</style>